.modal-push-announcement-container {
  right: 0;
  top: 0;
  position: fixed;
  height: 100vh;
  font-family: "Pretendard", sans-serif;
  max-width: 440px;

  .ant-modal-content {
    padding: 20px 0 0 0;
    .ant-modal-header {
      padding-left: 30px;
    }
    .ant-modal-close {
      right: 29px;
    }
  }

  .modal-body {
    height: calc(100vh - 120px);
    border-top: 1px solid #cfcdcd;
    border-bottom: 1px solid #cfcdcd;
    overflow-y: auto;
    padding: 0 40px 0 29px;

    .push-announcement-title {
      margin-top: 50px;
      padding: 10px 18px 5px 11px;
      border: 1px solid #dbdbdb;
      border-radius: 10px;

      .title {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 14px;
        color: #5c5c5c;
      }

      .detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5c5c5c;
        font-size: 14px;
      }
    }

    .push-announcement-content {
      border: 1px solid #dbdbdb;
      padding: 15px 34px 15px 19px;
      color: #5c5c5c;
      text-align: left;
      border-radius: 10px;
      margin-top: 12px;
    }

    .push-announcement-linkedPost {
      padding: 15px 11px;
      border: 1px solid #dbdbdb;
      border-radius: 10px;
      font-size: 14px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        width: 90%;
      }

      .btn-redirect button{
        min-width: 57px;
        width: 57px;
        height: 26px;
        padding: 2px 2px 2px 2px;
        border-radius: 5px;
        background-color: #31313b;
        font-family: "Arial Bold", "Arial Normal", "Arial", sans-serif;
        font-weight: 700;
        color: #ffffff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: normal;
      }
    }
  }

  .ant-modal-footer {
    margin-top: 0;
    padding: 10px 20px;
    .ant-btn {
      width: 57px;
      min-width: 57px;
      height: 40px;
      padding: 2px 2px 2px 2px;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      background-color: #4ecbc3;
      box-sizing: border-box;
      font-family: "Pretendard", sans-serif;
      color: #ffffff;
      text-align: center;
      line-height: normal;
    }
  }
}
