@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Thin.otf') format('opentype');
  // STUB Thin
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-ExtraLight.otf') format('opentype');
  // STUB ExtraLight
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Light.otf') format('opentype');
  // STUB Light
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Regular.otf') format('opentype');
  // STUB Regular Font
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Medium.otf') format('opentype');
  // STUB Medium Font
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-SemiBold.otf') format('opentype');
  // STUB SemiBold Font
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Bold.otf') format('opentype');
  // STUB Bold Font
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-ExtraBold.otf') format('opentype');
  // STUB ExtraBold Font
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Black.otf') format('opentype');
  // STUB Black Font
  font-weight: 900;
  font-style: normal;
}
