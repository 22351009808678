.content {
  ::before {
    position: relative;
    content: '';
  }

  .content__counter {
    font-size: 14px;
    line-height: 1.5714285714285714;
    position: absolute;
    bottom: -22px;
    inset-inline-end: 0;
    color: rgba(0, 0, 0, 0.45);
    white-space: nowrap;
    pointer-events: none;
  }
}

#editor {
  .toastui-editor-defaultUI {
    border-radius: 8px;
    overflow: hidden;
    border-color: #0d0d0d;

    &:focus-within {
      border-color: #4ecbc3;
    }
  }

  &.uploading .toastui-editor-popup-body {
    pointer-events: none;
  }
}
