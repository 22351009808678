.modal-delete-announcement {
  .ant-modal-body {
    min-height: unset;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 26px;
    span {
      margin: 0;
      fontSize: 16px;
      color: #333333;
    }
  }
  .ant-modal-footer {
    padding-top: 18px;
    padding-left: 12px;
    padding-right: 11px;

    .cancel-button {
      width: 108px;
      height: 35px;
      padding: 2px 2px 2px 2px;
      border-radius: 5px;
      border: 1px solid #4ecbc3;
      background-color: #ffffff;
      box-sizing: border-box;
      font-family: "Pretendard", sans-serif;
      color: #4ecbc3;
      text-align: center;
      line-height: normal;
      margin-right: 13px;
      cursor: pointer;
    }
    .ok-button {
      width: 108px;
      height: 35px;
      padding: 2px 2px 2px 2px;
      border-radius: 5px;
      background-color: #4ecbc3;
      box-sizing: border-box;
      font-family: "Pretendard", sans-serif;
      color: #ffffff;
      text-align: center;
      line-height: normal;
      border: none;
      cursor: pointer;
    }
    .ok-button-remove {
      width: 108px;
      height: 35px;
      padding: 2px 2px 2px 2px;
      border-radius: 5px;
      background-color: #ff576b;
      box-sizing: border-box;
      font-family: "Pretendard", sans-serif;
      color: #ffffff;
      text-align: center;
      line-height: normal;
      border: none;
      cursor: pointer;
    }
    .cancel-button-remove {
      width: 108px;
      height: 35px;
      padding: 2px 2px 2px 2px;
      border-radius: 5px;
      border: 1px solid #ff576b;
      background-color: #ffffff;
      box-sizing: border-box;
      font-family: "Pretendard", sans-serif;
      color: #ff576b;
      text-align: center;
      line-height: normal;
      cursor: pointer;
      margin-right: 13px;
    }
  }
}
